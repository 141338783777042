export enum ActionTypes {
    IS_SIGNED = 'IS_SIGNED',
    SIGN_IN = 'SIGN_IN',
    RESET_SIGN_IN = 'RESET_SIGN_IN',
    SIGN_OUT = 'SIGN_OUT',
    FETCH_UNIS_CUSTOMER = 'FETCH_UNIS_CUSTOMER',
    CHECK_UNIQUE = 'CHECK_UNIQUE',
    CREATE_USER = 'CREATE_USER',
    UPDATE_USER = 'UPDATE_USER',
    UPDATE_SHARE_INITIAL = 'UPDATE_SHARE_INITIAL',
    CONFIRMATION = 'CONFIRMATION',
    VERIFICATION = 'VERIFICATION',
    PW_RESET_SHARE = 'PW_RESET_SHARE',
    PW_RESET_USERNAME = 'PW_RESET_USERNAME',
    PW_RESET_DESTINATION = 'PW_RESET_DESTINATION',
    PW_RESET_CODE = 'PW_RESET_CODE',
    PW_RESET_ANSWER = 'PW_RESET_ANSWER',
    PW_NEW = 'PW_NEW',
    PW_RENEW = 'PW_RENEW',
    GET_UMID = 'GET_UMID',
    GET_UNIS_CD = 'GET_UNIS_CD',
    GET_ACCOUNT = 'GET_ACCOUNT',
    AUTH_TOKEN = 'AUTH_TOKEN',
    AUTH_DESTINATION = 'AUTH_DESTINATION',
    AUTH_LIMITED = 'AUTH_LIMITED',
    AUTH_ENCRYPTED = 'AUTH_ENCRYPTED',
    GET_AUTH_USER = 'GET_AUTH_USER',
    GET_USER_BY_USERNAME = 'GET_USER_BY_USERNAME',
    UNIS_MATCHING = 'UNIS_MATCHING',
    MEMBERS_MATCHING = 'MEMBERS_MATCHING',
    MEMBERS_MATCHING_VERIFY = 'MEMBERS_MATCHING_VERIFY',
    RESEND_MEMBERS_MATCHING_CODE = 'RESEND_MEMBERS_MATCHING_CODE',
    UNIS_MATCHING_VERIFY = 'UNIS_MATCHING_VERIFY',
    RESEND_MATCHING_CODE = 'RESEND_MATCHING_CODE',
    REBUILD_USER = 'REBUILD_USER',
}
