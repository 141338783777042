import { EntryParams } from './../../interfaces';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from 'vuex';
import { ActionTypes } from './action-types';
import { MutationTypes } from './mutation-types';
import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import {
    AuthActionsTypes,
    AuthStateTypes,
    IRootState,
    AuthUser,
    CreateUserParam,
    ConfirmationResult,
    VerificationResult,
    ResetDestination,
    PasswordParam,
    RegisterPartParams,
    IdPassParams,
    ForgotRequestParams,
} from '@/store/interfaces';
import apiClient from '@/client/api';
import { Conf } from '@/conf';
import router from '@/router';
const LOCAL_STORAGE_KEY = 'UMembers';
const SERVICE_LOCAL_STORAGE_KEY = 'UService';

export const actions: ActionTree<AuthStateTypes, IRootState> & AuthActionsTypes = {
    /*
     * 認証済みかどうかのチェック
     */
    async [ActionTypes.IS_SIGNED]({ commit }): Promise<boolean> {
        try {
            // console.log('Action isSigned check Start');
            const authData = await Auth.currentSession();
            // console.log(authData.getIdToken().payload.exp);
            const userName = authData.getIdToken().payload['cognito:username'];
            // console.log(`is-signed userName is ${userName}`);

            const response = await apiClient.get(`/auth/username?userName=${userName}`);
            if (!response || !response.data || !response.data.umid || !response.data.account || !response.data.account.userStatus) {
                router.push('/logout');
                return false;
            }

            //AuthCheck
            if (response.data.account.userStatus !== Conf.userStatus.code.active) {
                router.push('/logout');
                return false;
            }
            commit(MutationTypes.SET_AUTH_USER, response.data);

            if (authData) {
                if (response.data && response.data.umid) {
                    const newAuth: AuthUser = {
                        idToken: authData.getIdToken().getJwtToken(),
                        refreshToken: authData.getRefreshToken().getToken(),
                        accessToken: authData.getAccessToken().getJwtToken(),
                        expired: authData.getIdToken().payload['exp'],
                        umid: response.data.umid,
                    };
                    // console.log(`is-signed SET_AUTH umid is ${newAuth.umid}`);
                    commit(MutationTypes.SET_AUTH, newAuth);
                    return true;
                } else {
                    console.error(`[signIn ERROR]`);
                    //Sign Out
                    // await Auth.signOut({ global: false });
                    // localStorage.setItem(LOCAL_STORAGE_KEY, '');
                    // commit(MutationTypes.SET_AUTH, undefined);
                    // commit(MutationTypes.SET_AUTH_USER, undefined);
                    router.push('/logout');
                    return false;
                }
            } else {
                const localData = localStorage.getItem(LOCAL_STORAGE_KEY);
                if (localData) {
                    const data = JSON.parse(localData);
                    commit(MutationTypes.SET_AUTH, data.auth as AuthUser);
                    return true;
                }
            }
        } catch (error) {
            return false;
        }
        return false;
    },

    /*
     * リセット初回サインイン
     */
    async [ActionTypes.RESET_SIGN_IN]({ commit }, payload: { userName: string; password: string }): Promise<boolean> {
        console.log(payload);
        const authData = await Auth.signIn(payload.userName, payload.password).catch((error) => {
            console.error(`[signIn ERROR] ${JSON.stringify(error)}`);
            commit(MutationTypes.SET_AUTH, undefined);
            return false;
        });
        console.log('---------');
        const response = await apiClient.get(`/auth/username?userName=${payload.userName}`);
        console.log(response);
        if (!response || !response.data || !response.data.umid || !response.data.account || !response.data.account.userStatus) {
            return false;
        }
        //AuthCheck
        if (response.data.account.userStatus !== Conf.userStatus.code.active) {
            return false;
        }
        commit(MutationTypes.SET_AUTH_USER, response.data);

        if (authData) {
            const newAuth: AuthUser = {
                idToken: authData.signInUserSession.idToken.jwtToken,
                accessToken: authData.signInUserSession.idToken.accessToken,
                refreshToken: authData.signInUserSession.idToken.refreshToken,
                expired: authData.signInUserSession.idToken.expired,
                umid: response.data.umid,
            };
            commit(MutationTypes.SET_AUTH, newAuth);
        }
        return true;
    },
    /*
     * サインイン
     */
    async [ActionTypes.SIGN_IN]({ commit }, payload: { userName: string; password: string; isKeepLogin: boolean }) {
        const authData = await Auth.signIn(payload.userName, payload.password).catch((error) => {
            console.error(`[signIn ERROR] ${JSON.stringify(error)} }`);
            commit(MutationTypes.SET_AUTH, undefined);
            return null;
        });
        const response = await apiClient.get(`/auth/username?userName=${payload.userName}`);
        if (!response || !response.data || !response.data.umid || !response.data.account || !response.data.account.userStatus) {
            return null;
        }
        //AuthCheck
        if (response.data.account.userStatus !== Conf.userStatus.code.active) {
            return null;
        }
        commit(MutationTypes.SET_AUTH_USER, response.data);

        if (authData) {
            const newAuth: AuthUser = {
                idToken: authData.signInUserSession.idToken.jwtToken,
                accessToken: authData.signInUserSession.idToken.accessToken,
                refreshToken: authData.signInUserSession.idToken.refreshToken,
                expired: authData.signInUserSession.idToken.expired,
                umid: response.data.umid,
            };
            commit(MutationTypes.SET_AUTH, newAuth);
            //他サービスの遷移
            localStorage.setItem(SERVICE_LOCAL_STORAGE_KEY, JSON.stringify({ auth: newAuth, user: response.data }));
            if (payload.isKeepLogin) {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ auth: newAuth, user: response.data }));
            }
        } else {
            const localData = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (localData) {
                const data = JSON.parse(localData);
                commit(MutationTypes.SET_AUTH, data.auth as AuthUser);
            } else {
                console.error(`[signIn ERROR]localData not found `);
                return false;
                // router.push('/logout');
            }
        }
        return response.data;
    },
    /*
     * 認証ユーザーの取得と設定（ほかサービスからの遷移）
     */
    // eslint-disable-next-line no-empty-pattern
    async [ActionTypes.GET_AUTH_USER]({}, payload: { userName: string }) {
        const response = await apiClient.get(`/auth/username?userName=${payload.userName}`);
        if (!response || !response.data || !response.data.umid || !response.data.account || !response.data.account.userStatus) {
            router.push('/logout');
        }
        //AuthCheck
        if (response.data.account.userStatus !== Conf.userStatus.code.active) {
            router.push('/logout');
        }
        return response.data;
    },
    /*
     * 認証ユーザーの取得のみ
     */
    // eslint-disable-next-line no-empty-pattern
    async [ActionTypes.GET_USER_BY_USERNAME]({}, payload: { userName: string }) {
        const response = await apiClient.get(`/auth/username?userName=${payload.userName}`);
        if (!response || !response.data || !response.data.umid || !response.data.account) {
            return null;
        }
        return response.data;
    },
    /*
     * サインアウト
     */
    async [ActionTypes.SIGN_OUT]({ commit }) {
        await Auth.signOut({ global: true }).catch(async (error) => {
            console.error(`[signOut ERROR] ${JSON.stringify(error)}`);
        });
        localStorage.setItem(LOCAL_STORAGE_KEY, '');
        localStorage.setItem(SERVICE_LOCAL_STORAGE_KEY, '');
        sessionStorage.clear();
        localStorage.clear();
        commit(MutationTypes.SET_AUTH, undefined);
        commit(MutationTypes.SET_AUTH_USER, undefined);
    },

    /*
     * トークン認証
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.AUTH_TOKEN]({ commit }, payload: { token: string; umid: string }) {
        try {
            const response = await apiClient.post(`/auth/token`, payload);
            return response.data;
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    async inActivate({ commit }) {
        await Auth.signOut({ global: false }).catch(async (error) => {
            console.error(`[signOut ERROR] ${JSON.stringify(error)}`);
        });
        localStorage.setItem(LOCAL_STORAGE_KEY, '');
        commit(MutationTypes.SET_AUTH, undefined);
        commit(MutationTypes.SET_AUTH_USER, undefined);
    },

    /*
     * UNIS顧客情報の取得
     */
    async [ActionTypes.FETCH_UNIS_CUSTOMER]({ commit }, payload: { unisCustomerCode: string }) {
        try {
            const response = await apiClient.get(`/auth/uniscode?unisCustomerCode=${payload.unisCustomerCode}`);
            commit(MutationTypes.SET_UNIS_CUSTOMER, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * ユーザーIDが一意かどうかのチェック
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.CHECK_UNIQUE]({ commit }, payload: { userName: string }): Promise<boolean> {
        try {
            const response = await apiClient.post('/auth/unique', payload);
            return response.data.result;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return false;
        }
    },

    /*
     * UMIDでユーザーアカウント情報取得
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.GET_UMID]({ commit }, payload: { umid: string; unis?: boolean }): Promise<any> {
        if (payload.unis === undefined) {
            payload.unis = true;
        }
        try {
            const response = await apiClient.get(`/auth/umid?umid=${payload.umid}&unis=${payload.unis}`);
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    /*
     * unisCutomerCodeでユーザーアカウント情報取得
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.GET_UNIS_CD]({ commit }, payload: { unisCustomerCode: string }): Promise<any> {
        try {
            const response = await apiClient.get(`/auth/uniscode?unisCustomerCode=${payload.unisCustomerCode}`);
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    /*
     * UMIDでユーザーアカウントのみの情報取得
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.GET_ACCOUNT]({ commit }, payload: { umid: string }): Promise<any> {
        try {
            const response = await apiClient.get(`/auth/account?umid=${payload.umid}`);
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    /*
     * 宛先でユーザー取得
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.AUTH_DESTINATION]({ commit }, payload: { destination: string }): Promise<any> {
        try {
            const response = await apiClient.get(`/auth/destination?destination=${payload.destination}`);
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },
    /*
     * Web申し込みユーザー登録
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.CREATE_USER]({ commit }, payload: CreateUserParam): Promise<boolean> {
        try {
            const response = await apiClient.post('/auth/user', payload);
            if (!response) {
                return false;
            }
            return true;
            //ここでセットはしない
            // console.log(response);
            // commit(MutationTypes.SET_AUTH, response.data);
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            if (err.response?.status === 404) {
                console.error(err.response?.status);
                return false;
            }
            return false;
        }
    },

    /*
     * NeOS電子契約ユーザーのユーザー情報更新
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.UPDATE_USER]({ commit }, payload: RegisterPartParams): Promise<boolean> {
        try {
            const response = await apiClient.put('/auth/user', payload);
            if (!response) {
                return false;
            }
            return true;
            //ここでセットはしない
            // console.log(response);
            // commit(MutationTypes.SET_AUTH, response.data);
        } catch (error) {
            console.error(error);
            return false;
        }
    },
    /*
     * Shareユーザーのユーザー情報更新
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.UPDATE_SHARE_INITIAL]({ commit }, payload: RegisterPartParams): Promise<boolean> {
        try {
            const response = await apiClient.put('/auth/share', payload);
            if (!response) {
                return false;
            }
            return true;
            //ここでセットはしない
            // console.log(response);
            // commit(MutationTypes.SET_AUTH, response.data);
        } catch (error) {
            console.error(error);
            return false;
        }
    },
    /*
     * 電話番号かメールアドレス送信
     */
    async [ActionTypes.CONFIRMATION]({ commit }, payload: { destination: string }): Promise<void> {
        try {
            const response = await apiClient.post('/auth/confirmation', payload);
            const data = response.data;
            data.destination = payload.destination;
            commit(MutationTypes.SET_CONFIRMATION_RESULT, data as ConfirmationResult);
        } catch (error) {
            console.error(error);
        }
    },

    /*
     * 確認コード照合
     */
    async [ActionTypes.VERIFICATION](
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        { commit },
        payload: { confirmationCode: string; destination?: string; umid: string }
    ): Promise<VerificationResult | null> {
        try {
            const response = await apiClient.post('/auth/verification', payload);
            return response.data;
        } catch (error) {
            console.error(error);
            return null;
        }
    },

    /*
     * シェアユーザーのパスワードリセット
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.PW_RESET_SHARE]({ commit }, payload: { umid: string }) {
        try {
            await apiClient.post('/password/reset/share', payload);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },

    /*
     * ログインIDでのパスワードリセット
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.PW_RESET_USERNAME]({ commit }, payload: { userName: string }): Promise<number> {
        try {
            await apiClient.post('/password/reset/username', payload);
            return 200;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            if (err.response?.status === 404) {
                console.error(err.response?.status);
                return 404;
            }
            return 500;
        }
    },

    /*
     * メールか携帯電話でのパスワードリセット
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.PW_RESET_DESTINATION]({ commit }, payload: { destination: string }): Promise<number> {
        try {
            await apiClient.post('/password/reset/destination', payload);
            return 200;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            if (err.response?.status === 404) {
                console.error(err.response?.status);
                return 404;
            }
            return 500;
        }
    },

    /*
     * USENお客様番号でのパスワードリセット
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.PW_RESET_CODE]({ commit }, payload: { unisCustomerCode: string }): Promise<number> {
        try {
            const response = await apiClient.post('/password/reset/code', payload);
            const data = response.data;
            commit(MutationTypes.SET_RESET_DESTINATION, data as ResetDestination);
            return 200;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            if (err.response?.status === 404) {
                console.error(err.response?.status);
                return 404;
            }
            return 500;
        }
    },

    /*
     * 秘密の質問でのパスワードリセット
     */
    async [ActionTypes.PW_RESET_ANSWER](
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        { commit },
        payload: { applicantName: string; securityQuestion: string; securityAnswer: string; birthYMD: string; storeNameKana: string }
    ): Promise<number> {
        try {
            const response = await apiClient.post('/password/reset/answer', payload);
            const data = response.data;
            commit(MutationTypes.SET_RESET_DESTINATION, data as ResetDestination);
            return 200;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            if (err.response?.status === 404) {
                console.error(err.response?.status);
                return 404;
            }
            return 500;
        }
    },

    /*
     * 新規パスワードのみのパスワード再設定
     */
    async [ActionTypes.PW_NEW]({ commit }, payload: PasswordParam): Promise<number> {
        console.log(payload);
        try {
            const response = await apiClient.post('/password/new', payload);
            commit(MutationTypes.SET_AUTH, response.data);
            return 200;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.message);
            console.error(err.response?.status);
            return 500;
        }
    },

    /*
     * 古いパスワードが必要なパターンのパスワード再設定
     */
    async [ActionTypes.PW_RENEW]({ commit }, payload: { auth: AuthUser; param: PasswordParam }) {
        try {
            const response = await apiClient.post('/password/renew', payload.param, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_AUTH, response.data);
        } catch (error) {
            console.error(error);
            // TODO: エラーメッセージ表示
        }
    },
    /*
     * 時限UMIDの取得
     */
    async [ActionTypes.AUTH_LIMITED]({ commit }, payload: { auth: AuthUser }) {
        try {
            const response = await apiClient.post(`/auth/limited`, payload.auth, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            commit(MutationTypes.SET_LIMITED_DATA, response.data);
        } catch (error) {
            console.error(error);
        }
    },
    /*
     * 暗号化パラメータの取得
     */
    async [ActionTypes.AUTH_ENCRYPTED]({ commit }, payload: { auth: AuthUser; serviceCode: string }) {
        try {
            const response = await apiClient.post(
                `/auth/encrypted`,
                { auth: payload.auth, serviceCode: payload.serviceCode },
                {
                    headers: {
                        Authorization: payload.auth.idToken,
                        'x-umid': payload.auth.umid,
                    },
                }
            );
            commit(MutationTypes.SET_ENCRYPTED_DATA, response.data);
        } catch (error) {
            console.error(error);
        }
    },
    /*
     * UNISマッチング
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.UNIS_MATCHING]({ commit }, payload: EntryParams): Promise<number> {
        try {
            const response = await apiClient.post('/auth/matching', payload);
            if (response.data) {
                commit(MutationTypes.SET_ENTRY_CHALLENGE_UMID, response.data.umid);
            }
            return 0;
        } catch (error) {
            const err = error as AxiosError;
            //すでに顧客が存在する場合
            if (err.response?.status === 404) {
                return 2;
            }
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return 1;
        }
    },
    /*
     * UNISマッチングで認証コードの確認
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.UNIS_MATCHING_VERIFY]({ commit }, payload: { umid: string }): Promise<boolean> {
        try {
            const response = await apiClient.post('/auth/matching/verification', payload);
            console.log(response);
            if (response.data.result) {
                commit(MutationTypes.SET_ENTRY_PROVISIONAL_UMID, response.data.umid);
                return true;
            }
            return false;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return false;
        }
    },

    /*
     * UNISマッチングで認証コードの再送
     */
    /* eslint-disable no-empty-pattern */
    async [ActionTypes.RESEND_MATCHING_CODE]({}, payload: { challengeUmid: string }): Promise<boolean> {
        try {
            const response = await apiClient.post('/auth/matching/resend', payload);
            if (response.data.result) {
                return true;
            }
            return false;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return false;
        }
    },
    /*
     * MEMBERSマッチング
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.MEMBERS_MATCHING]({ commit }, payload: ForgotRequestParams): Promise<string> {
        try {
            const response = await apiClient.post('/auth/members/matching', payload);
            commit(MutationTypes.SET_RESET_DESTINATION, {
                authMailAddress: payload.mailAddress,
                authMobileNumber: '',
            });
            return response.data.result;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return '';
        }
    },
    /*
     * MEMBERSマッチングで認証コード確認
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.MEMBERS_MATCHING_VERIFY]({ commit }, payload: { umid: string; confirmationCode: string }): Promise<boolean> {
        try {
            const response = await apiClient.post('/auth/members/verification', payload);
            if (response.data.result) {
                return true;
            }
            return false;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return false;
        }
    },
    /*
     * MEMBERSマッチングで認証コードの再送
     */
    /* eslint-disable no-empty-pattern */
    async [ActionTypes.RESEND_MEMBERS_MATCHING_CODE]({}, payload: { umid: string }): Promise<boolean> {
        try {
            const response = await apiClient.post('/auth/members/resend', payload);
            if (response.data.result) {
                return true;
            }
            return false;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return false;
        }
    },
    /*
     * 新しいユーザー名とパスワードで作り直す
     */
    /* eslint-disable no-empty-pattern */
    async [ActionTypes.REBUILD_USER]({ commit }, payload: { auth: AuthUser; params: IdPassParams }): Promise<boolean> {
        const apiParams = payload.params;
        apiParams.password = payload.params.password1;
        try {
            const response = await apiClient.post('/auth/rebuild', apiParams, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-umid': payload.auth.umid,
                },
            });
            if (response.data) {
                commit(MutationTypes.SET_AUTH, response.data.auth);
                commit(MutationTypes.SET_AUTH_USER, response.data.user);
                localStorage.setItem(SERVICE_LOCAL_STORAGE_KEY, JSON.stringify({ auth: response.data.auth, user: response.data.user }));
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ auth: response.data.auth, user: response.data.user }));
                return true;
            }
            return false;
        } catch (error) {
            const err = error as AxiosError;
            console.error(err.response?.status);
            console.error(err.response?.statusText);
            return false;
        }
    },
};
